.range input[type='range'] {
  height: 16px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  align-items: center;
}

.range input[type='range']:focus {
  outline: none;
}

.range input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: #7eabf9;
  border-radius: 5px;
}
.range input[type='range']::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background: #2765f5;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

/* datalist {
  display: flex;
  height: auto;
  bottom: 16px;
  justify-content: space-between;
}

datalist option {
  height: 8px;
  border: 1px solid;
} */

/* input[type='range']:focus::-webkit-slider-runnable-track {
  background: #7eabf9;
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000;
  background: #7eabf9;
  border-radius: 5px;
  border: 1px solid #000000;
}
input[type='range']::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000;
  border: 1px solid #000000;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: #2765f5;
  cursor: pointer;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: #7eabf9;
  border: 1px solid #000000;
  border-radius: 10px;
  box-shadow: 1px 1px 1px #000000;
}
input[type='range']::-ms-fill-upper {
  background: #7eabf9;
  border: 1px solid #000000;
  border-radius: 10px;
  box-shadow: 1px 1px 1px #000000;
}
input[type='range']::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 1px #000000;
  border: 1px solid #000000;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: #2765f5;
  cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
  background: #7eabf9;
}
input[type='range']:focus::-ms-fill-upper {
  background: #7eabf9;
} */
