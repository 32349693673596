@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'SF Pro', 'GM Sans', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'SF Pro';
  src: url('./assets/fonts/SF_Pro/SF-Pro-Display-Medium.otf');
  font-weight: 500;
}
@font-face {
  font-family: 'GM Sans';
  src: url('./assets/fonts/GM_Sans/GmarketSansTTFMedium.ttf');
  font-weight: 500;
}

#check-box-1:checked ~ .check-1 {
  @apply text-opacity-100;
  animation: check-anime 0.5s 1;
}

@keyframes check-anime {
  0%,
  100% {
    @apply transform scale-100;
  }
  50% {
    @apply text-opacity-0 transform scale-150;
  }
}
